import React, {useEffect, useState} from 'react';
import image from "../../../../assets/images/background/subtle-prism.svg";
import { baseUrl } from "../../../../config/const";
import { Link, useParams } from "react-router-dom";
import parser from "html-react-parser";
import FsLightbox from "fslightbox-react";
import Loading from '../../../common/Loader';

const EventDetailPage = () => {
  // const data = useLoaderData();
  // const eventDetail = data.data.event;
  // const images = data.data.images;
  // const events = data.data.events;
  const [toggler, setToggler] = useState(false);
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/events/" + params.id);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params.id]);
  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.event && data.data.event.name}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.event && data.data.event.name}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <div className="row">
              <div className="col-md-9">
                <h2 className="title">{data.data.event && data.data.event.name}</h2>
                {data.data.event &&
                  data.data.event.content &&
                  parser(data.data.event.content)}
              </div>
              <div className="col-md-3">
                <div className="side-bar sticky-top">
                  <div className="widget widget-bx widget_archive">
                    <h5 className="widget-title bgColor">Events Gallery</h5>
                    <ul>
                      <li>
                        <Link className="active">{data.data.event.name}</Link>
                      </li>
                      {data.data.events &&
                        data.data.events.map((event) => (
                          <li key={event.id}>
                            <Link to={`/events/${event.id}`}>
                              {event.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-full content-inner bg-gray">
          <div className="container">
            <h2 className="title">Event Photos</h2>

            <div className="gallery-box wow fadeInLeft">
              <div className="row mfp-gallery portfolio-bx1">
                {data.data.images ? (
                  data.data.images.map((image, index) => (
                    <div className="col-md-4" key={index}>
                      <div
                        className="dlab-box dlab-gallery-box overlay-gallery-bx1 m-b30 wow fadeIn"
                        data-wow-duration="2s"
                        data-wow-delay="0.2s"
                      >
                        <div
                          className="dlab-media dlab-img-overlay1 dlab-img-effect portbox1"
                          onClick={() => setToggler(!toggler)}
                        >
                          <img src={image} alt="" />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <span className="mfp-link" title="">
                                {" "}
                                <i className="fa fa-search icon-bx-xs"></i>{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Event Images Available</p>
                )}

                <FsLightbox toggler={toggler} sources={data.data.images} type="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                }
};

export default EventDetailPage;
