import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { baseUrl } from "../../../config/const";
import ReCAPTCHA from "react-google-recaptcha";

const PartnerForm = ({ reasons, type }) => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const refnameRef = useRef(null);
  const addressRef = useRef(null);
  const reasonRef = useRef(null);
  const messageRef = useRef(null);
  const captchaRef = useRef(null);

  const [status, setStatus] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [captchaMessage, setCaptchaMsg] = useState(null);


  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values, e) => {
    e.preventDefault();
    setSubmitting(true); // Disable the button immediately upon submission

    let token = captchaRef.current.getValue();
    if (token) {

        const enrollmentData = {
          name: values.name,
          email: values.email,
          phone: values.phone,
          refname: values.refname,
          address: values.address,
          reason: values.reason,
          message: values.message,
          type: values.type,
        };

        try {
          let response = await fetch(baseUrl + "/become-partners", {
            method: "POST",
            body: JSON.stringify(enrollmentData),
          });

          let responseJson = await response.json();
          if (responseJson.data.status === 200) {
            setStatus("success");
            captchaRef.current.reset();
            // Set Input Fields to Blank
            e.target.reset();
          } else {
            setStatus("error");
            captchaRef.current.reset();
          }
        } catch (error) {
          setStatus("error");
          captchaRef.current.reset();
        }finally{
          setSubmitting(false); // Re-enable the button after response is received
        }
    }else{
      setCaptchaMsg("Please verify captcha");
    }   
  };
  return (
    <div className="section-full content-inner contact-box">
      <div className="container content-div">
        <div className="student-enrollment bgColor radius-md mt-5">
          <h2 className="title text-white mt-0">Fill The Form</h2>
          <div className="dzFormMsg">
            {status === "success" && (
              <p className="success">
                Thanks for Contacting. We will contact you soon.
              </p>
            )}
            {status === "error" && (
              <p className="formError">Something went wrong.</p>
            )}
          </div>
          <form
            method="post"
            className="dzForm"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" id="type" value={type} {...register("type")} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" Contact person’s Name *"
                    ref={nameRef}
                    id="name"
                    {...register("name", {
                      required: "Required",
                      message: "Name is required",
                    })}
                  />
                  {errors.name && (
                    <span className="formError">{errors.name.message}</span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Contact person’s Email *"
                    ref={emailRef}
                    id="email"
                    {...register("email", {
                      required: "Required",
                      message: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid Email Address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="formError">{errors.email.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Contact Number *"
                    ref={phoneRef}
                    id="phone"
                    {...register("phone", {
                      required: "Required",
                      message: "Phone is required",
                      minLength: {
                        value: 10,
                        message: "must be of 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "must be less than 10 digits",
                      },
                    })}
                  />
                  {errors.phone && (
                    <span className="formError">{errors.phone.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={type === "College" ? "College Name *" : "Company Name *" }
                    ref={refnameRef}
                    id="college_name"
                    {...register("refname", {
                      required: "Required",
                    })}
                  />
                  {errors.refname && (
                    <span className="formError">{errors.refname.message}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <textarea
                    rows="2"
                    className="form-control"
                    placeholder={type === "College" ? "College Address *" : "Company Address *" }
                    ref={addressRef}
                    id="address"
                    {...register("address", {
                      required: "Required",
                    })}
                  ></textarea>
                  {errors.address && (
                    <span className="formError">{errors.address.message}</span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <select
                    ref={reasonRef}
                    id="reason"
                    defaultValue={""}
                    {...register("reason", {
                      required: "Required",
                    })}
                  >
                    <option value="">
                      -Select Partner for-
                    </option>
                    {reasons.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors.reason && (
                    <span className="formError">{errors.reason.message}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <textarea
                    rows="8"
                    placeholder="Your Message here *"
                    id="comment"
                    className="form-control"
                    ref={messageRef}
                    name="message"
                    {...register("message", {
                      required: "Required",
                      message: "Message is required",
                      minLength: {
                        value: 10,
                        message: "must contain 10 letters",
                      },
                      maxLength: {
                        value: 400,
                        message: "Max 400 is allowed",
                      },
                    })}
                  ></textarea>
                  {errors.message && (
                    <span className="formError">{errors.message.message}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                />
                <br/> 
                <span className="formError">{captchaMessage && captchaMessage}</span>
                </div>
              </div>

              <div className="col-md-12 col-sm-12 text-center">
                <div className="button-container">
                  {/* <button className="button" >Submit</button> */}
                  <button className="button" type="submit" disabled={submitting} >
                    {submitting ? 'Submitting...' : 'Submit' }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
