import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config/const";
import image from "../../../assets/images/background/subtle-prism.svg";
import parser from 'html-react-parser';
import StudentEnrollmentForm from "./EnrollmentForm";
import Loading from "../../common/Loader";

const StudentEnrollmentPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/student-enrollment");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {
    return (
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">{ data.data.student_enrollment.title}</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/">
                      <i className="fa fa-home"></i>
                    </Link>
                  </li>
                                <li>{ data.data.student_enrollment.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block pattern-bg">
          <div className="section-full content-inner">
            <div className="container content-div">
              <h2 className="title">
                {data.data.student_enrollment.title}
              </h2>
              {parser(data.data.student_enrollment.content)}
            </div>
          </div>

                {/* Form */}
                <StudentEnrollmentForm data={data.data.student_enrollment} />
        </div>
      </div>
    );
        }
};

export default StudentEnrollmentPage;
