import React, { useEffect, useState } from "react";
import {  Link, useParams } from "react-router-dom";
import { baseUrl } from "../../../config/const";
import image from "../../../assets/images/background/subtle-prism.svg";
import parser from "html-react-parser";
import Loading from "../../common/Loader";

const HowWorkPage = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/how-we-works/" + params.id);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params.id]);

  if (isLoading) {
    return (<Loading />
    );
  }

  if (data) {
  const content = data.data.content;
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          justifyContent: "top center",
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{content.title}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{content.title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div
          id="About"
          className="section-full overflow-hidden content-inner"
         >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div
                  className="wow fadeInLeft"
                  data-wow-delay="0.4s"
                  data-wow-duration="1s"
                >
                  <div className="hidden-text">
                                      <span>{content.title}</span>
                  </div>

                  <h1 className="title">{content.title}</h1>
                </div>
                <div
                  className="wow fadeInLeft mt-5 mr-6"
                  data-wow-delay="0.4s"
                  data-wow-duration="1s"
                >
                  {parser(content.content)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
      }
};

export default HowWorkPage;
