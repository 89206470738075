import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../config/const";
import image from "../../assets/images/background/subtle-prism.svg";
import { ExternalLink } from "react-external-link";
import Loading from "../common/Loader";

const PartnerPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/partners");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (data) {
  const college = data.data.college;
  const industry = data.data.industry;

  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Partners</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>Partners</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        {college && (
          <div className="section-full content-inner bg-svg">
            <div className="container content-div">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="title">{data.data.college_title}</h2>
                </div>
                <div className="col-md-12 wow fadeInLeft">
                  <div className="client-logos">
                    {college.map((item) => (
                      <ExternalLink href={item.link} className="item" key={item.id}>
                        <div className="client-logo-inner">
                          <img alt="client-logo" src={item.image} />
                        </div>
                      </ExternalLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {industry && (
          <div className="section-full content-inner bg-gray">
            <div className="container content-div">
              <div className="row">
                <div className="col-md-12 wow fadeInRight">
                  <h2 className="title">{data.data.industry_title}</h2>
                  <div className="company-logos">
                    {industry.map((item) => (
                      <ExternalLink href={item.link} className="item" key={item.id}>
                        <div className="client-logo-inner">
                          <img alt="client-logo" src={item.image} />
                        </div>
                      </ExternalLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
                    }
};

export default PartnerPage;
