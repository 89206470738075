import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../config/const";
import image from "../../assets/images/background/subtle-prism.svg";
import { ExternalLink } from "react-external-link";
import Loading from '../common/Loader';

const MentorPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/mentors");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (data) {

  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.pageTitle}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.pageTitle}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <div className="row">
              {data.data.mentors &&
                data.data.mentors.map((item) => (
                  <div
                    className={`col-md-12 wow ${
                      item.type === "odd" ? "fadeInLeft" : "fadeInRight"
                    }`}
                    data-wow-duration="2s"
                    data-wow-delay="0.2s"
                    key={item.id}
                  >
                    <div className="mentor-list bgColor radius-md">
                      <div className="profile-pic dlab-media">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="mentor-details">
                        <div className="team-title-bx">
                          <h2 className="team-title">
                            {item.name}
                            <span className="clearfix">{item.title}</span>
                          </h2>

                          <ul className="social-list">
                            {item.fb && (
                              <li>
                                <ExternalLink
                                  className="btn-link fa fa-facebook"
                                  target="_blank"
                                  href={item.fb}
                                >
                                  <i></i>
                                </ExternalLink>
                              </li>
                            )}
                            {item.x && (
                              <li>
                                <ExternalLink
                                  className="btn-link fa fa-twitter"
                                  target="_blank"
                                  href={item.x}
                                >
                                  <i></i>
                                </ExternalLink>
                              </li>
                            )}
                            {item.linkedin && (
                              <li>
                                <ExternalLink
                                  href={item.linkedin}
                                  target="_blank"
                                  className="btn-link fa fa-linkedin"
                                >
                                  <i></i>
                                </ExternalLink>
                              </li>
                            )}
                            {item.youtube && (
                              <li>
                                <ExternalLink
                                  href={item.youtube}
                                  target="_blank"
                                  className="btn-link fa fa-youtube-play"
                                >
                                  <i></i>
                                </ExternalLink>
                              </li>
                            )}
                          </ul>
                        </div>

                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                            }
}

export default MentorPage;
