import React, {useEffect, useState} from 'react';
import image from "../../assets/images/background/subtle-prism.svg";
import { baseUrl } from "../../config/const";
import { Link, NavLink, useParams } from "react-router-dom";
import { ExternalLink } from "react-external-link";
import Loading from '../common/Loader';

const TestimonialPage = () => {
  // const data = useLoaderData();
  // const testimonials = data.data.testimonials;
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/testimonials/" + params.type);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params.type]);

  if (isLoading) {
    return (<Loading />
    );
  }

  if (data) {
  const testimonials = data.data.testimonials;
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.pageTitle}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.pageTitle}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <div className="row">
              <div className="col-md-9">
                <h2 className="title">{data.data.pageTitle}</h2>
                <div className="row">
                  {testimonials ? (
                    testimonials.map((item) => (
                      <div className="col-md-12" key={item.id}>
                        <div className="testimonial-1 testimonial-inner bgColor">
                          <div className="testimonial-pic radius">
                            <img
                              src={item.image}
                              width="100"
                              height="100"
                              alt=""
                            />
                          </div>
                          <div className="testimonial-text text-left">
                            <p>{item.testimonial}</p>

                            {item.video && (
                              <div className="button-container mb-2">
                                <ExternalLink
                                  className="button"
                                  target="_blank"
                                  href={item.video}
                                >
                                  Watch Video
                                </ExternalLink>
                              </div>
                            )}

                            <div className="testimonial-detail">
                              <strong className="testimonial-name">
                                {item.name}
                              </strong>
                              <span className="testimonial-position">
                                {item.title}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Testimonials Found</p>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="side-bar sticky-top">
                  <div className="widget widget-bx widget_archive">
                    <h5 className="widget-title bgColor">Testimonials</h5>
                    <ul>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : undefined
                          }
                          to="/testimonials/student"
                        >
                          Student's Testimonials
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/testimonials/college"
                          className={({ isActive }) =>
                            isActive ? "active" : undefined
                          }
                        >
                          College Professional's Testimonials
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/testimonials/industry"
                          className={({ isActive }) =>
                            isActive ? "active" : undefined
                          }
                        >
                          Industry Professional's Testimonials
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                        }
};

export default TestimonialPage;

