import React, { useEffect, useState } from "react";
import { baseUrl } from "../../config/const";
import image from "../../assets/images/background/subtle-prism.svg";
import { Link, useParams } from "react-router-dom";
import parser from "html-react-parser";
import Loading from "../common/Loader";
import './css/Services.css';

const ServiceDetailPage = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/services/" + params.id);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params.id]);

  if (isLoading) {
    return (<Loading />
    );
  }

  if (data) {
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.service && data.data.service.name}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.service && data.data.service.name}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <div className="row">
              <div className="col-md-9 service_content">
                <h2 className="title">{data.data.service && data.data.service.name}</h2>
                {data.data.service && parser(data.data.service.content)}
              </div>
              <div className="col-md-3">
                <div className="side-bar sticky-top">
                  <div className="widget widget-bx widget_archive">
                    <h5 className="widget-title bgColor">Services</h5>
                    <ul>
                      <li>
                        <Link className="active">{data.data.service.name}</Link>
                      </li>
                      {data.data.services &&
                        data.data.services.map((item) => (
                          <li key={item.id}>
                            <Link to={`/services/${item.id}`}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                        }
};

export default ServiceDetailPage;

