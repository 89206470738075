import React, {useEffect, useState} from 'react';
import { baseUrl } from "../../config/const";
import image from "../../assets/images/background/subtle-prism.svg";
import { Link } from "react-router-dom";
import Loading from '../common/Loader';

const ServiceListingPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/services");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {
    return (
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Services</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/">
                      <i className="fa fa-home"></i>
                    </Link>
                  </li>
                  <li>Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block pattern-bg">
          <div className="section-full content-inner">
            <div className="container content-div">
              <div
                className="wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <div className="row">
                  {data.data.services ? (
                    data.data.services.map((service) => (
                      <div className="col-lg-4 col-md-6 m-b30" key={service.id}>
                        <div className="services icon-bx-wraper sr-iconbox">
                          <Link to={service.id}>
                            <img src={service.image} alt="" />

                            <div className="icon-content">
                                        <h3 className="dlab-tilte">{service.name}</h3>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Services Available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
                  }
};

export default ServiceListingPage;
