import { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { baseUrl } from "../../../config/const";

const BlogCommentForm = ({ props }) => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const commentRef = useRef(null);

  const [status, setStatus] = useState(undefined);

  const { handleSubmit, register, formState: { errors } } = useForm();
  const onSubmit = async (values,e) => {
    e.preventDefault();

    const commentData = {
      id : values.blog_id,
      name: values.name,
      phone: values.phone,
      email: values.email,
      comment: values.comment,
    };
    
    try {
      let response = await fetch(baseUrl + "/blogComment", {
        method: "POST",
        body: JSON.stringify(commentData),
      });

      let responseJson = await response.json();
      if (responseJson.data.status === 200) {
        setStatus('success');
        // Set Input Fields to Blank
        e.target.reset();
      } else {
        setStatus('error');
      }
    } catch (error) {
        setStatus("error");
    }

  }

  return (
    <div className="comment-respond" id="respond">
      <h3 className="comment-reply-title" id="reply-title">
        Leave A Comment
      </h3>

      {status === "success" && (
        <p className="success">Thanks for Commenting.</p>
      )}
      {status === "error" && <p className="formError">Something went wrong.</p>}
      <form
        className="comment-form"
        id="commentform"
        method="post"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="hidden"
          name="blog_id"
          value={props}
          {...register("blog_id")}
        />
        <p className="comment-form-author">
          <label htmlFor="Name">
            Name <span className="required">*</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            ref={nameRef}
            id="Name"
            {...register("name", {
              required: "Required",
            })}
          />
          {errors.name && (
            <span className="formError">{errors.name.message}</span>
          )}
        </p>

        <p className="comment-form-email">
          <label htmlFor="email">
            Email <span className="required">*</span>
          </label>
          <input
            type="text"
            name="email"
            placeholder="Email"
            ref={emailRef}
            id="email"
            {...register("email", {
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
          />
          {errors.email && (
            <span className="formError">{errors.email.message}</span>
          )}
        </p>

        <p className="comment-form-url">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            name="phone"
            ref={phoneRef}
            placeholder="Phone"
            id="phone"
            {...register("phone", {
              required: "Required",
              minLength: {
                value: 10,
                message: "must be of 10 digits",
              },
              maxLength: {
                value: 10,
                message: "must be less than 10 digits",
              },
            })}
          />
          {errors.phone && (
            <span className="formError">{errors.phone.message}</span>
          )}
        </p>

        <p className="comment-form-comment">
          <label htmlFor="comment">Comment</label>
          <textarea
            rows="8"
            placeholder="Add a Comment"
            id="comment"
            ref={commentRef}
            name="comment"
            {...register("comment", {
              required: "Required",
              minLength: {
                value: 30,
                message: "must contain 30 letters",
              },
              maxLength: {
                value: 400,
                message: "Max 400 is allowed",
              },
            })}
          ></textarea>
          {errors.comment && (
            <span className="formError">{errors.comment.message}</span>
          )}
        </p>

        <div className="form-submit button-container">
          <button className="button">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default BlogCommentForm;

