import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { baseUrl } from "../../../config/const";
import ReCAPTCHA from "react-google-recaptcha";

const StudentEnrollmentForm = ({ data }) => {
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const collegenameRef = useRef(null);
    const collegeaddressRef = useRef(null);
    const streamRef = useRef(null);
    const yearRef = useRef(null);
    const courseRef = useRef(null);
    const messageRef = useRef(null);
    const captchaRef = useRef(null);

    const [status, setStatus] = useState(undefined);
    const [captchaMessage, setCaptchaMsg] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm();

    const onSubmit = async (values, e) => {
      e.preventDefault();
      setSubmitting(true); // Disable the button immediately upon submission

      let token = captchaRef.current.getValue();
      if (token) {
          const enrollmentData = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            college_name: values.college_name,
            college_address: values.college_address,
            stream: values.stream,
            year: values.year,
            course: values.course,
            message: values.message,
          };

          try {
            let response = await fetch(baseUrl + "/student-enrollment", {
              method: "POST",
              body: JSON.stringify(enrollmentData),
            });

            let responseJson = await response.json();
            if (responseJson.data.status === 200) {
              setStatus("success");
              // Set Input Fields to Blank
              e.target.reset();
              captchaRef.current.reset();
            } else {
              setStatus("error");
              captchaRef.current.reset();
            }
          } catch (error) {
            setStatus("error");
            captchaRef.current.reset();
          }finally{
            setSubmitting(false); // Re-enable the button after response is received
          }
      }else{
        setCaptchaMsg("Please verify captcha");
      }    
    };
    
    return (
      <div className="section-full content-inner contact-box">
        <div className="container content-div">
          <div className="student-enrollment bgColor radius-md mt-5">
            <h2 className="title text-white mt-0">{data.title}</h2>
            <div className="dzFormMsg">
              {status === "success" && (
                <p className="success">
                  Thanks for Contacting. We will contact you soon.
                </p>
              )}
              {status === "error" && (
                <p className="formError">Something went wrong.</p>
              )}
            </div>
            <form
              method="post"
              className="dzForm"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name *"
                      ref={nameRef}
                      id="name"
                      {...register("name", {
                        required: "Required",
                        message: "Name is required",
                      })}
                    />
                    {errors.name && (
                      <span className="formError">{errors.name.message}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email Address *"
                      ref={emailRef}
                      id="email"
                      {...register("email", {
                        required: "Required",
                        message: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid Email Address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="formError">{errors.email.message}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="number"
                      name="phone"
                      className="form-control"
                      ref={phoneRef}
                      placeholder="Phone *"
                      id="phone"
                      {...register("phone", {
                        required: "Required",
                        message: "Phone is required",
                        minLength: {
                          value: 10,
                          message: "must be of 10 digits",
                        },
                        maxLength: {
                          value: 10,
                          message: "must be less than 10 digits",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span className="formError">{errors.phone.message}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="College Name *"
                      ref={collegenameRef}
                      id="college_name"
                      {...register("college_name", {
                        required: "Required",
                        message: "College Name is required",
                      })}
                    />
                    {errors.college_name && (
                      <span className="formError">
                        {errors.college_name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <textarea
                      rows="2"
                      className="form-control"
                      placeholder="College Address *"
                      ref={collegeaddressRef}
                      id="college_address"
                      {...register("college_address", {
                        required: "Required",
                        message: "College Address is required",
                      })}
                    ></textarea>
                    {errors.college_address && (
                      <span className="formError">
                        {errors.college_address.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      ref={streamRef}
                      id="stream"
                      defaultValue={""}
                      {...register("stream", {
                        required: "Required",
                        message: "Stream is required",
                      })}
                    >
                      <option value="">-Select Stream-</option>
                      {data.stream.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      ref={yearRef}
                      id="year"
                      defaultValue={""}
                      {...register("year", {
                        required: "Required",
                        message: "Year is required",
                      })}
                    >
                      <option value="">-Select Year/Semester-</option>
                      {data.year.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors.year && (
                      <span className="formError">{errors.year.message}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Courses inter *"
                      ref={courseRef}
                      id="course"
                      {...register("course", {
                        required: "Required",
                        message: "Course is required",
                      })}
                    />
                    {errors.course && (
                      <span className="formError">{errors.course.message}</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <textarea
                      rows="8"
                      placeholder="Your Message here *"
                      id="comment"
                      className="form-control"
                      ref={messageRef}
                      name="message"
                      {...register("message", {
                        required: "Required",
                        message: "Message is required",
                        minLength: {
                          value: 10,
                          message: "must contain 10 letters",
                        },
                        maxLength: {
                          value: 400,
                          message: "Max 400 is allowed",
                        },
                      })}
                    ></textarea>
                    {errors.message && (
                      <span className="formError">
                        {errors.message.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={captchaRef}
                  />
                  <br/> 
                  <span className="formError">{captchaMessage && captchaMessage}</span>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 text-center">
                  <div className="button-container">
                    {/* <button className="button" >Submit</button> */}
                    <button className="button" type="submit" disabled={submitting} >
                      {submitting ? 'Submitting...' : 'Submit' }
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
};

export default StudentEnrollmentForm;