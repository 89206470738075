import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config/const";
import image from "../../../assets/images/background/subtle-prism.svg";
import Loading from "../../common/Loader";

const BlogListing = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      async function fetchData() {
        setIsLoading(true);
        try {
          const response = await fetch(baseUrl + "/blogs");
          const json = await response.json();
          setData(json);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
      fetchData();
    }, []);
    
    if (isLoading) {
      return <Loading />;
    }
    if (data) {
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.pageTitle}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.pageTitle}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <h2 className="title">{data.data.pageTitle}</h2>

            <div
              className="row blog-style  wow fadeInLeft"
              data-wow-delay="0.2s"
              data-wow-duration="2s"
            >
              {data.data.blogs &&
                data.data.blogs.map((blog, index) => (
                  <div className="col-md-4" key={index}>
                    <Link to={blog.id}>
                      <div className="blog-post blog-grid style1 ">
                        <div className="blog-media">
                          <img src={blog.image} alt="" />
                          <div className="dlab-post-meta">
                            <ul>
                              <li className="post-date">
                                <i className="fa fa-calendar-o"></i> {blog.date}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="dlab-post-text">
                          <div className="dlab-post-title">
                            <h4 className="post-title font-weight-600">
                              <Link to={blog.id}>{blog.title}</Link>
                            </h4>
                          </div>

                          <p>{blog.short_description}</p>
                          <div className="blog-btn">
                            <Link to={blog.id}>
                              Read more <i className="fa fa-arrow-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                }
};

export default BlogListing;
