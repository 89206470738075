import { ExternalLink } from "react-external-link";

const ContactBranch = ({data}) => {
  return (
    <div className="section-full content-inner">
      <div className="container content-div">
        <h2 className="title mt-0">Branches</h2>
        <div className="wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
          <div className="row">
            {data.map((item) => (
              <div className="col-lg-4 col-md-6 m-b30" key={item.id}>
                <div className="icon-bx-wraper sr-iconbox">
                  <div className="location">
                    <img src={item.image} alt="" />

                    <div className="icon-content">
                      <h3 className="dlab-tilte">{item.city}</h3>

                      <p>{item.address}</p>

                      {item.emails.map((email) => (
                        <div key={email}>
                        <p>
                          <ExternalLink
                            href={`mailto:${email}`}
                            target="_blank"
                          >
                            {email}
                          </ExternalLink>
                          </p>
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBranch;
