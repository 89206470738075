import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config/const";
import image from "../../../assets/images/background/subtle-prism.svg";
import parser from "html-react-parser";
import PartnerForm from "./PartnerForm";
import Loading from '../../common/Loader';


const CollegePartnerPage = () => {
  // const data = useLoaderData();
  // const detail = data.college_data;
  const type = "College";
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/college-partner");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.college_data.title}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.college_data.title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <div className="row">
              <div className="col-md-9">
                <h2 className="title">{data.data.college_data.title}</h2>
                {/* Content */}
                {parser(data.data.college_data.content)}
              </div>

              <div className="col-md-3">
                <div className="side-bar sticky-top">
                  <div className="widget widget-bx widget_archive">
                    <h5 className="widget-title bgColor">Become a Partner</h5>
                    <ul>
                      {data.data.second_data && (
                        <li style={{textTransform : 'none'}}>
                          <Link to="/become-industry-partners">
                            {data.data.second_data.title}
                          </Link>
                        </li>
                      )}
                      <li style={{textTransform : 'none'}}>
                        <Link
                          className="active"
                          to="/become-college-partners"
                        >
                          {data.data.college_data.title}
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PartnerForm reasons={data.data.college_data.reasons} type={type} />
      </div>
    </div>
  );
                      }
};

export default CollegePartnerPage;
