import React, {useEffect, useState} from 'react';
import image from "../../../../assets/images/background/subtle-prism.svg";
import { baseUrl } from "../../../../config/const";
import { Link } from "react-router-dom";
import Loading from '../../../common/Loader';

const EventListingPage = () => {
  // const data = useLoaderData();
  //   const events = data.data.events;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/events");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  
    
    if (isLoading) {
      return <Loading />;
    }
    if (data) {
  
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Events Gallery</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>Events Gallery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container">
            <div className="row">
              {data.data.events ? (
                data.data.events.map((event) =>
                  event.type === "odd" ? (
                    <div
                      className="col-lg-12 wow fadeInRight"
                      data-wow-duration="2s"
                            data-wow-delay="0.2s"
                        key= {event.id}
                    >
                      <div className="blog-post blog-half blog-half1 mb-5">
                        <div className="blog-media">
                          <img src={event.image} alt="" />
                        </div>
                        <div className="post-info">
                          <div className="dlab-post-title left">
                            <h3 className="post-title font-weight-600">
                              {event.name}
                            </h3>
                          </div>
                          <div className="dlab-post-meta">
                            <ul>
                              <li className="post-date text-primary">
                                <i className="fa fa-clock-o text-primary"></i>{" "}
                                {event.event_date}
                              </li>
                            </ul>
                          </div>
                          <div className="dlab-post-text">
                            <p>{event.description}</p>
                            <div className="button-container">
                              <Link to={event.id}>
                                <button className="button">Read More</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="col-lg-12 wow fadeInLeft"
                      data-wow-duration="2s"
                                data-wow-delay="0.4s"
                                key={event.id}
                    >
                      <div className="blog-post blog-half blog-half1  mb-5">
                        <div className="post-info text-right">
                          <div className="dlab-post-title right">
                            <h3 className="post-title font-weight-600">
                              {event.name}
                            </h3>
                          </div>
                          <div className="dlab-post-meta">
                            <ul>
                              <li className="post-date  text-primary">
                                                    <i className="fa fa-clock-o text-primary"></i> 
                                                    {event.event_date}
                              </li>
                            </ul>
                          </div>
                          <div className="dlab-post-text">
                            <p>{event.description}
                            </p>
                            <div className="button-container">
                              <Link to={event.id}>
                                <button className="button">Read More</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="blog-media">
                          <img src={event.image} alt="" />
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <p>No Events Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
              }
};

export default EventListingPage;

