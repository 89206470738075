import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { baseUrl } from "../../../config/const";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
    const fnameRef = useRef(null);
    const lnameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const messageRef = useRef(null);
    const captchaRef = useRef(null);

    const [status, setStatus] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [captchaMessage, setCaptchaMsg] = useState(null);

    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm();

    const onSubmit = async (values, e) => {
      e.preventDefault();
      setSubmitting(true); // Disable the button immediately upon submission

      let token = captchaRef.current.getValue();
      if(token) { 
      const contactData = {
        fname: values.fname,
        lname: values.lname,
        phone: values.phone,
        email: values.email,
        message: values.message,
      };
  
      try {
        let response = await fetch(baseUrl + "/contact-us", {
          method: "POST",
          body: JSON.stringify(contactData),
        });
  
        let responseJson = await response.json();
        if (responseJson.data.status === 200) {
          setStatus("success");
          e.target.reset();
          captchaRef.current.reset();
        } else {
          setStatus("error");
          captchaRef.current.reset();
        }
      } catch (error) {
        setStatus("error");
        captchaRef.current.reset();
      } finally {
        setSubmitting(false); // Re-enable the button after response is received

      }
      }else{
        setCaptchaMsg("Please verify captcha");
      }  
    };
    
  return (
    <div className="student-enrollment bgColor radius-md">
      <h2 className="title text-white mt-0">
        Contact us - Please fill-up the form
      </h2>
      <div className="dzFormMsg">
        {status === "success" && (
          <p className="success">
            Thanks for Contacting. We will contact you soon.
          </p>
        )}
        {status === "error" && (
          <p className="formError">Something went wrong.</p>
        )}
      </div>
      <form
        method="post"
        className="dzForm"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="First name *"
                ref={fnameRef}
                id="fname"
                {...register("fname", {
                  required: "Required",
                  message: "First name is required",
                })}
              />
              {errors.fname && (
                <span className="formError">{errors.fname.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Last name *"
                ref={lnameRef}
                id="lname"
                {...register("lname", {
                  required: "Required",
                  message: "Last name is required",
                })}
              />
              {errors.lname && (
                <span className="formError">{errors.lname.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email Address *"
                ref={emailRef}
                id="email"
                {...register("email", {
                  required: "Required",
                  message: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid Email Address",
                  },
                })}
              />
              {errors.email && (
                <span className="formError">{errors.email.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="number"
                name="phone"
                className="form-control"
                ref={phoneRef}
                placeholder="Phone *"
                id="phone"
                {...register("phone", {
                    required: "Required",
                    message : "Phone is required",
                  minLength: {
                    value: 10,
                    message: "must be of 10 digits",
                  },
                  maxLength: {
                    value: 10,
                    message: "must be less than 10 digits",
                  },
                })}
              />
              {errors.phone && (
                <span className="formError">{errors.phone.message}</span>
              )}
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
              <textarea
                rows="8"
                placeholder="Your Message here *"
                              id="comment"
                              className="form-control"
                ref={messageRef}
                name="message"
                {...register("message", {
                    required: "Required",
                    message: "Message is required",
                  minLength: {
                    value: 10,
                    message: "must contain 10 letters",
                  },
                  maxLength: {
                    value: 400,
                    message: "Max 400 is allowed",
                  },
                })}
              ></textarea>
              {errors.message && (
                <span className="formError">{errors.message.message}</span>
              )}
            </div>
          </div>

           <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
            />
            <br/> 
            <span className="formError">{captchaMessage && captchaMessage}</span>
            </div>
          </div> 
          <div className="col-md-12 col-sm-12 text-center">
            <div className="button-container">
              {/* <button className="button" >Submit</button> */}
              <button className="button" type="submit" disabled={submitting} >
                {submitting ? 'Submitting...' : 'Submit' }
              </button>
            </div>

          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
