import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../config/const";
import image from "../../assets/images/background/subtle-prism.svg";
import parser from "html-react-parser";
import Loading from '../common/Loader';

const CoursePage = () => {
const [data, setData] = useState(null);
const [isLoading, setIsLoading] = useState(false);
useEffect(() => {
  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await fetch(baseUrl + "/getCourse");
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
  fetchData();
}, []);

  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {
    return (
      <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          justifyContent: "top center",
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.course && data.data.course.title}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.course && data.data.course.title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div class="section-full   content-inner">
          <div className="container content-div">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div
                  className="wow fadeInLeft"
                  data-wow-delay="0.4s"
                  data-wow-duration="1s"
                >
                  <h2 className="title" style={{fontSize:"42px"}}>{data.data.course && data.data.course.title}</h2>
                </div>
                <div
                  className="wow fadeInLeft mt-0 mr-6"
                  data-wow-delay="0.4s"
                  data-wow-duration="1s"
                >
                  {data.data.course && parser(data.data.course.content)}
                </div>
              </div>
            </div>

            <div className="row">
              {data.data.course.courses &&
                data.data.course.courses.map((item) => (
                  <div className="col-lg-4 col-md-6 m-b30" key={item.id}>
                    <div className="icon-bx-wraper sr-iconbox">
                      <Link to={item.id}>
                      <img src={item.image} alt="" />

                      <div className="icon-content">
                        <h3 className="dlab-tilte">{item.title}</h3>
                        <p>{item.short_description}</p>
                      </div>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>

          </div>
        </div>
      </div>
      </div>
    );
  }
};

export default CoursePage;

