import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../config/const";
import image from "../../assets/images/background/subtle-prism.svg";
import Loading from '../common/Loader';

const MilestonePage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/getMileStones");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.pageTitle}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.pageTitle}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container">
            <div className="row">
              {data.data.milestone ? data.data.milestone.map((item,index) => (
                <>
                      {item.type !== 'even' &&
                          <div
                              className="col-md-12 wow fadeInRight"
                              data-wow-duration="2s"
                              data-wow-delay="0.2s"
                              key={index}
                          >
                              <div className="history-box right">
                                  <div className="history-details">
                                      <div className="history-head">
                                          <h2>{ item.year}</h2>
                                          <h3>{ item.subtitle }</h3>
                                      </div>
                                      <p>
                                         {item.content}
                                      </p>
                                  </div>
                                  <div className="history-media">
                                      <img src={item.image} alt={item.alt_name} />
                                  </div>
                              </div>
                          </div>
                      }
                      { item.type !== 'odd' &&
                          <div
                              className="col-md-12 wow fadeInLeft"
                              data-wow-duration="2s"
                              data-wow-delay="0.4s"
                              key={index}
                          >
                              <div className="history-box">
                                  <div className="history-details">
                                      <div className="history-head">
                                          <h2>{ item.year }</h2>
                                          <h3>{ item.subtitle }</h3>
                                      </div>
                                      <p>{item.content}</p>
                                  </div>
                                  <div className="history-media">
                                      <img src={item.image} alt={ item.alt_name } />
                                  </div>
                              </div>
                          </div>
                      }
                </>
              )) : <p>No Milestone Available.</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                    }
};

export default MilestonePage;
