import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config/const";
import image from "../../../assets/images/background/subtle-prism.svg";
import { ExternalLink } from "react-external-link";
import ContactForm from "./contactForm";
import ContactBranch from "./contactBranch";
import Loading from '../../common/Loader';

const ContactPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/contact-us-data");
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (data) {

  const detail = data.data.detail;
  const registered_detail = data.data.registered_detail;
  const branches = data.data.branches;

  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Contact Us</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner contact-box">
          <div className="container content-div">
            <div className="row align-items-center mb-5">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="icon-bx-wraper m-b30 left">
                  <div className="icon-md m-b20 m-t5">
                    <Link className="icon-cell text-primary">
                      <i className="ti-headphone-alt"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h4 className="dlab-tilte m-b5">Phone</h4>
                    <div>
                      {detail.phones.map((phone) => (
                        <div key={phone}>
                          {phone}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="icon-bx-wraper m-b30 left">
                  <div className="icon-md m-b20 m-t5">
                    <ExternalLink
                      href="https://www.google.com/maps/place/Nukleus+Coworking+%26+Managed+Offices/@28.5024945,77.410697,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce910b1acf48b:0x58ac43aea0e28990!8m2!3d28.5024945!4d77.4132719!16s%2Fg%2F11h15x9wkg?entry=ttu"
                      className="icon-cell text-primary"
                      target="_blank"
                    >
                      <i className="ti-location-pin"></i>
                    </ExternalLink>
                  </div>
                  <div className="icon-content">
                    <h4 className="dlab-tilte m-b5">Corporate Office </h4>
                    <p>{detail.address}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="icon-bx-wraper m-b30 left">
                  <div className="icon-md m-b20 m-t5">
                    <Link className="icon-cell text-primary">
                      <i className="ti-email"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h4 className="dlab-tilte m-b5">Email</h4>
                    <div>
                      {detail.email.map((email) => (
                        <div key={email}>
                          <p>{email}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ContactForm />
          </div>
        </div>

        {registered_detail && (
          <div className="section-full content-inner bgBlue contact-box p-30">
            <div className="container content-div">
              <h2 className="title text-white">Our Registered Address</h2>

              <div className="icon-bx-wraper m-b30 left">
                <div className="icon-md m-b20 m-t5">
                  <ExternalLink
                    href="https://www.google.com/maps/place/Nukleus+Coworking+%26+Managed+Offices/@28.5024945,77.410697,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce910b1acf48b:0x58ac43aea0e28990!8m2!3d28.5024945!4d77.4132719!16s%2Fg%2F11h15x9wkg?entry=ttu"
                    className="icon-cell text-primary"
                    target="_blank"
                  >
                    <i className="ti-location-pin"></i>
                  </ExternalLink>
                </div>
                <div className="icon-content">
                  <h4 className="dlab-tilte m-b5 big-font">
                    {registered_detail.name}{" "}
                  </h4>
                  <p className="medium-font">{registered_detail.address}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {branches && <ContactBranch data={branches} />}
      </div>
    </div>
  );
        }
};

export default ContactPage;

