import React, {useEffect, useState} from 'react';
import image from "../../../../assets/images/background/subtle-prism.svg";
import { baseUrl } from "../../../../config/const";
import { Link } from "react-router-dom";
import { ExternalLink } from "react-external-link";
import Loading from '../../../common/Loader';

const GalleryLisiting = () => {
    const [data, setData] = useState(null);
const [isLoading, setIsLoading] = useState(false);
useEffect(() => {
  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await fetch(baseUrl + "/gallery");
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
  fetchData();
}, []);

  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {

  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Gallery</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>Gallery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <h2 className="title">Photos Gallery</h2>

            <div className="gallery-box wow fadeInLeft">
              <div className="row mfp-gallery portfolio-bx1">
                {data.data.image_gallery ? (
                  data.data.image_gallery.map((album, index) => (
                    <div className="col-md-4" key={index}>
                      <div
                        className="dlab-box dlab-gallery-box overlay-gallery-bx1 m-b30 wow fadeIn"
                        data-wow-duration="2s"
                        data-wow-delay="0.4s"
                      >
                        <Link to={album.id}>
                          <div className="dlab-media dlab-img-overlay1 dlab-img-effect portbox1">
                            <img src={album.image} alt="" />
                          </div>
                          <div className="gallery-caption text-center">
                              <h5>{album.name}</h5>
                            </div>
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Image Album Available</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="section-full content-inner  bg-gray">
          <div className="container content-div">
            <h2 className="title">Videos Gallery</h2>

            <div className="row mfp-gallery portfolio-bx1">
              {data.data.videos ? (
                data.data.videos.map((video, index) => (
                  <div className="col-md-4" key={index}>
                      <div
                        className="dlab-box dlab-gallery-box overlay-gallery-bx1 m-b30 wow fadeInRight"
                        data-wow-duration="2s"
                        data-wow-delay="0.4s"
                      >
                        <ExternalLink href={video.url} target="_blank">
                          <div className="dlab-media dlab-img-overlay1 dlab-img-effect portbox1">
                            <img src={video.thumbnail} alt="" />
                            <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <ExternalLink
                                    href={video.url}
                                    target="_blank"
                                  >
                                    <i className="fa fa-play icon-bx-xs"></i>
                                  </ExternalLink>
                                </div>
                              </div>
                          </div>
                          <div className="gallery-caption text-center">
                              <h5>{video.title}</h5>
                            </div>
                        </ExternalLink>
                      </div>
                    </div>

                ))
              ) : (
                <p>No Videos Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
              }
};

export default GalleryLisiting;
