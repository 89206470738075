import React, {useEffect, useState} from 'react';
import { Link,useParams } from "react-router-dom";
import { baseUrl } from "../../../config/const";
import image from "../../../assets/images/background/subtle-prism.svg";
import parser from "html-react-parser";
import BlogCommentListing from './BlogCommentListing';
import Loading from "../../common/Loader";

const BlogDetail = () => {
    // const data = useLoaderData();
    // const blog = data.data.data.blog;
    // const recentBlogs = data.data.data.recentBlogs;
    const params = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      async function fetchData() {
        setIsLoading(true);
        try {
          const response = await fetch(baseUrl + "/blogs/" + params.id);
          const json = await response.json();
          setData(json);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
      fetchData();
    }, [params.id]);
    
    if (isLoading) {
      return <Loading />;
    }
    if (data) {
      const blog = data.data.blog;
      const recentBlogs = data.data.recentBlogs;
    return (
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">{blog.title}</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/">
                      <i className="fa fa-home"></i>
                    </Link>
                  </li>
                  <li>{blog.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block pattern-bg">
          <div className="section-full content-inner">
            <div className="container content-div">
              <div className="row">
                <div className="col-md-9">
                  <div className="blog-post blog-single blog-post-style-2 sidebar">
                    <h2 className="title">{blog.title}</h2>
                    <div className="dlab-post-info">
                      <div className="dlab-post-text p-0">
                        {parser(blog.content)}
                      </div>
                    </div>
                  </div>

                  <hr />

                  {/* Blog Comment Section Here */}
                  <BlogCommentListing
                    comment={data.data.comments}
                    count={data.data.count}
                    blogId={blog.id}
                  />
                </div>

                <div className="col-md-3">
                  <div className="side-bar sticky-top">
                    <div className="widget widget-bx widget_archive">
                      <h5 className="widget-title bgColor">Blogs</h5>
                      <ul>
                        <li>
                          <Link className="active">{blog.title}</Link>
                        </li>
                        {recentBlogs &&
                          recentBlogs.map((subBlog, index) => (
                            <li key={index}>
                              <Link to={`/blogs/${subBlog.id}`}>
                                {subBlog.title}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
                          }
};

export default BlogDetail;


