import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../../config/const";
import image from "../../assets/images/background/subtle-prism.svg";
import parser from "html-react-parser";
import Loading from "../common/Loader";

const CourseDetail = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/course-detail/" + params.id);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params.id]);

  if (isLoading) {
    return (<Loading />
    );
  }

  if (data) {
    return (
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">{data.data.course.title}</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/">
                      <i className="fa fa-home"></i>
                    </Link>
                  </li>
                  <li>{data.data.course.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block pattern-bg">
          <div className="section-full content-inner">
            <div className="container content-div">
              <div className="row">
                <div className="col-md-9">
                  <h2 className="title">{data.data.course.title}</h2>
                  {parser(data.data.course.content)}
                </div>

                <div className="col-md-3">
                  <div className="side-bar sticky-top">
                    <div className="widget widget-bx widget_archive">
                      <h5 className="widget-title bgColor">Courses</h5>
                      <ul>
                        <li>
                          <Link className="active">
                            {data.data.course.title}
                          </Link>
                        </li>
                        {data.data.courses &&
                          data.data.courses.map((item) => (
                            <li>
                              <Link to={`/courses/${item.id}`}>
                                {item.title}{" "}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CourseDetail;
