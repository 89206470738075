import BlogCommentForm from "./BlogCommentForm";

const BlogCommentListing = (props) => {
  const comments = props.comments;
  const count = props.count;
  return (
    <div className="clear m-b30" id="comment-list">
      <div className="comments-area" id="comments">
        <h6 className="comments-title">{count} Comments</h6>
        <div>
          <ol className="comment-list">
            {comments &&
              comments.map((comment) => (
                <li className="comment" key={comment.id}>
                  <div className="comment-body">
                    <div className="comment-author vcard">
                      <cite className="fn">{comment.name}</cite>
                      <span className="says">says:</span>
                    </div>
                    <p>{comment.comment}</p>
                    <div className="comment-meta">
                      {" "}
                      <span>{comment.added_date}</span>
                    </div>
                  </div>
                </li>
              ))}
          </ol>
          {/* Form Come Here */}
          <BlogCommentForm props={props.blogId} />
        </div>
      </div>
    </div>
  );
};

export default BlogCommentListing;
