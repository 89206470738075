import React, {  useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { baseUrl } from "../../../config/const";
import image from "../../../assets/images/background/subtle-prism.svg";
import line from "../../../assets/images/line.png";
import "../studentCertificate/InternCertificate.css";
import Loading from '../../common/Loader';

const InternCertificate = () => {
  // const data = useLoaderData();
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/student-intern-certificates/" + params.id);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params.id]);

  if (isLoading) {
    return <Loading />;
  }
  if (data) {
  const Certdata = data.data; 
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Certificate</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>Certificate</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="certificate-box">
                  <div className="certificate-completed">
                    <h2 className="c-name">Completed by {Certdata.student_name}</h2>
                    <span className="c-date">
                      <strong>{Certdata.completion_date}</strong>
                    </span>
                    <span className="c-hour">
                      {/* <strong>35 Hours</strong> */}
                    </span>
                    <p className="c-context">
                      {Certdata.student_name}'s account is verified. Winnovation
                      certifies their successful completion of{" "}
                      <u>{Certdata.course_name}</u>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="bg">
                  <div className="logo1">
                    <img className="c-logo1" src={Certdata.logo} alt="logo" />
                    <img
                      className="c-logo21"
                      src={Certdata.university_logo}
                      alt="logo"
                    />
                  </div>

                  <div className="certificate-content">
                    <h1 className="heading-1 first-head">CERTIFICATE</h1>
                    <h3 className="heading-3 first-head">
                      <img className="w-60" src={line} alt="logo" />
                      &nbsp;Awarded&nbsp;
                      <img className="w-60" src={line} alt="logo" />
                    </h3>

                    <p className="text-size">
                        {Certdata.text1} <b>"{Certdata.course_name}"</b> {Certdata.text2} <br/>
                        {Certdata.text3} <b>"{Certdata.partner_name}"</b> {Certdata.text4}
                    </p>

                    <h1 className=" heading-1 name">{Certdata.student_name}</h1>
                    
                    <h4 className="heading-4 enroll-no">
                      Enrollment No. {Certdata.enrollment_number}
                    </h4>
                    <p className="date">From <span className="from_date">{Certdata.from_date}</span> &nbsp; To &nbsp; <span className="to_date">{Certdata.to_date}</span> </p>
                    <p className="text-size">{Certdata.text5}</p>
                  </div>

                  <div className="qr-sign">
                    <div className="qr">
                      {/* <img
                        src={
                          "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" +
                          Certdata.qr_code +
                          "&choe=UTF-8"
                        }
                        title="Student Certificate"
                        alt="Student_certificate"
                      />
                      <h6 className="heading-6">Scan to Verify</h6> */}
                    </div>
                    <div className="sign text-center">
                      <img src={Certdata.sign_image} alt="" />
                      <p className="small-font">
                        <strong>
                          Director
                          <br />
                          {Certdata.signee_name}
                          <br />
                          {Certdata.institute_name}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                      }
};

export default InternCertificate;
