import React, {useEffect, useState} from 'react';
import { baseUrl } from "../../../../config/const";
import image from "../../../../assets/images/background/subtle-prism.svg";
import { Link, useParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import Loading from '../../../common/Loader';

const GalleryDetailPage = () => {
  const [toggler, setToggler] = useState(false);
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(baseUrl + "/gallery/" + params.id);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [params.id]);
  
  if (isLoading) {
    return <Loading />;
  }
  if (data) {
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr dlab-bnr-inr-lg overlay-primary-middle bg-pt"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">{data.data.gallery.name}</h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>{data.data.gallery.name}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block pattern-bg">
        <div className="section-full content-inner">
          <div className="container content-div">
            <div className="row">
              <div className="col-md-9">
                <div className="gallery-box wow fadeInLeft">
                  <div className="row mfp-gallery portfolio-bx1">
                    {data.data.images ? (
                      data.data.images.map((image, index) => (
                        <div className="col-md-4" key={index}>
                          <div
                            className="dlab-box dlab-gallery-box overlay-gallery-bx1 m-b30 wow fadeIn"
                            data-wow-duration="2s"
                            data-wow-delay="0.2s"
                          >
                            <div
                              className="dlab-media dlab-img-overlay1 dlab-img-effect portbox1"
                              onClick={() => setToggler(!toggler)}
                            >
                              <img src={image} alt="" />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <span className="mfp-link" title="">
                                    {" "}
                                    <i className="fa fa-search icon-bx-xs"></i>{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Images Available</p>
                    )}

                    <FsLightbox
                      toggler={toggler}
                      sources={data.data.images}
                      type="image"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="side-bar sticky-top">
                  <div className="widget widget-bx widget_archive">
                    <h5 className="widget-title bgColor">Photos Gallery</h5>
                    <ul>
                      <li>
                        <Link className="active">{data.data.gallery.name}</Link>
                      </li>
                      {data.data.albums &&
                        data.data.albums.map((album) => (
                          <li key={album.id}>
                            <Link to={`/gallery/${album.id}`}>
                              {album.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
                        }
};

export default GalleryDetailPage;

