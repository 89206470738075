import { ProgressBar } from "react-loader-spinner";
export default function Loading() {
  return (
    <div className="page-content bg-white"><div style={{ color: "black", padding: "15% 20% 20% 45%" , height: "400px" }}><ProgressBar
  height="80"
  width="80"
  ariaLabel="progress-bar-loading"
  wrapperStyle={{}}
  wrapperClass="progress-bar-wrapper"
  borderColor = '#014da2'
  barColor = '#ed7d31'
/></div></div>
  );
}
